import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import CausePageTemplate from '../components/CausePageTemplate'
import Layout from '../components/Layout'


const CausePage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <CausePageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        meta_title={post.frontmatter.meta_title}
        meta_description={post.frontmatter.meta_description}
        quote1={post.frontmatter.quote1}
        author1={post.frontmatter.author1}
        title2={post.frontmatter.title2}
        description2={post.frontmatter.description2}
        section2_img={post.frontmatter.section2_img}
        section2_alt={post.frontmatter.section2_alt}
        quote3={post.frontmatter.quote3}
        author3={post.frontmatter.author3}
        title4={post.frontmatter.title4}
        description4={post.frontmatter.description4}
        section4_img={post.frontmatter.section4_img}
        section4_alt={post.frontmatter.section4_alt}
        quote5={post.frontmatter.quote5}
        author5={post.frontmatter.author5}
        title6={post.frontmatter.title6}
        description6={post.frontmatter.description6}
        section6_img={post.frontmatter.section6_img}
        section6_alt={post.frontmatter.section6_alt}
        quote7={post.frontmatter.quote7}
        author7={post.frontmatter.author7}
      />
    </Layout>
  )
}

CausePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default CausePage

export const pageQuery = graphql`
  query CausePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        meta_title
        meta_description
        quote1
        author1
        title2
        description2
        section2_img
        section2_alt
        quote3
        author3
        title4
        description4
        section4_img
        section4_alt
        quote5
        author5
        title6
        description6
        section6_img
        section6_alt
        quote7
        author7
      }
    }
  }
`
