import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import './index.css'
import Link from 'gatsby-link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faQuoteLeft } from '@fortawesome/free-solid-svg-icons'

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..




const CausePageTemplate = ({
  title,
  subtitle,
  meta_title,
  meta_description,
  quote1,
  author1,
  title2,
  description2,
  section2_img,
  section2_alt,
  quote3,
  author3,
  title4,
  description4,
  section4_img,
  section4_alt,
  quote5,
  author5,
  title6,
  description6,
  section6_img,
  section6_alt,
  quote7,
  author7,
}) => {

  if(typeof window !== 'undefined' || typeof document !== 'undefined'){
    if (window.location.pathname.indexOf('admin') > 0) {
      AOS.init({
        disable: false,
      });
    } else AOS.init({disable: false});
  }
    return (


    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
      </Helmet>
      <section id="causebackground" className='hero is-primary is-bold is-medium'>
          <div className='hero-body'>
            <div className='container'>
              <div className='columns'>
                <div className='column is-10 is-offset-1'>
                  <div className='section'>
                  <div>
                    <h1 className='title'>
                      {title}
                    </h1></div>
                    <div >
                    <h2 className='subtitle'>
                      {subtitle}
                    </h2></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
      <section className='section3 partners'>
        <div className='container padbottom'>
          <div className="is-centered padtop">
            <span className="icon has-text-success">
              <FontAwesomeIcon icon={faQuoteLeft} />
            </span>
          </div>
          <div data-aos="zoom-out"
          data-aos-duration="1000">
          <h5 className="styledHeader is-centered">{quote1}</h5></div>
          <div data-aos="fade-up"
          data-aos-duration="1000">
          <h5 className="is-centered padtop padbottom"> {author1} </h5></div>
        </div>
      </section>
      <section className='section3 aboutus'>
          <div className='columns'>
            <div className='column is-half evolve'>
              <div className='column is-10 is-offset-1 is-sized'>
                <div className="bodyHeaderGroup">
                <div data-aos="fade-right"
                data-aos-duration="1000">
                  <h5 className="styledHeader">{title2}</h5>
                  </div>
                </div>
                <div className="mapTextGroup">
                <div data-aos="fade-up"
                data-aos-duration="1000">
                  <h5 className="bodyText"> {description2}</h5>
                  </div>
                </div>
              </div>          
            </div>
            <div className='column is-half imageholder is-sized cause'>
              <img alt={section2_alt} loading='lazy' src={section2_img}></img>
            </div>
          </div>
      </section>
      <section className='section3 partners'>
        <div className='container padbottom'>
          <div className="is-centered padtop">
            <span className="icon has-text-success">
              <FontAwesomeIcon icon={faQuoteLeft} />
            </span>
          </div>
          <div data-aos="zoom-out"
          data-aos-duration="1000">
          <h5 className="styledHeader is-centered">{quote3}</h5></div>
          <div data-aos="fade-up"
          data-aos-duration="1000">
          <h5 className="is-centered padtop padbottom"> {author3} </h5></div>
        </div>
      </section>
      <section className='section3'>
          <div className='columns'>
            <div className='column is-half cause ppeimage'>
              <img alt={section4_alt} loading='lazy' src={section4_img}></img>
            </div>
            <div className='column is-half ppeneed'>
              <div className='column is-10 is-offset-1'>
                <div className="bodyHeaderGroup">
                <div data-aos="fade-left"
                data-aos-duration="1000">
                  <h5 className="styledHeader">{title4}</h5>
                  </div>
                </div>
                <div className="mapTextGroup">
                <div data-aos="fade-up"
                data-aos-duration="1000">
                  <h5 className="bodyText"> {description4} </h5>
                  </div>
                </div>
              </div>          
            </div>
          </div>
      </section>
      <section className='section3 partners'>
        <div className='container padbottom'>
          <div className="is-centered padtop">
            <span className="icon has-text-success">
              <FontAwesomeIcon icon={faQuoteLeft} />
            </span>
          </div>
          <div data-aos="zoom-in"
                data-aos-duration="1000">
          <h5 className="styledHeader is-centered">{quote5}
</h5> </div>
          <div data-aos="fade-up"
                data-aos-duration="1000">
          <h5 className="is-centered padtop padbottom"> {author5} </h5>
          </div>
        </div>
      </section>
      <section className='section3 aboutus'>
          <div className='columns'>
            <div className='column is-half stateneed'>
              <div className='column is-10 is-offset-1 is-sized'>
                <div className="bodyHeaderGroup">
                <div data-aos="fade-right"
                data-aos-duration="1000">
                  <h5 className="styledHeader">{title6}</h5> </div>
                </div>
                <div className="mapTextGroup">
                <div data-aos="fade-up"
                data-aos-duration="1000">
                  <h5 className="bodyText"> {description6}
                  </h5>
                  </div>
                </div>
              </div>          
            </div>
            <div className='column is-half cause stateimage'>
              <img alt={section6_alt} loading='lazy' src={section6_img}></img>
            </div>
          </div>
      </section>
      <section className='section3 partners'>
        <div className='container padbottom'>
          <div className="is-centered padtop">
            <span className="icon has-text-success">
              <FontAwesomeIcon icon={faQuoteLeft} />
            </span>
          </div>
          <div data-aos="zoom-in"
                data-aos-duration="1000">
          <h5 className="styledHeader is-centered">{quote7}</h5></div>
          <div data-aos="fade-up"
                data-aos-duration="1000">
          <h5 className="is-centered padtop padbottom"> {author7} </h5></div>
        </div>
      </section>
    </div>
  )
}
// 

CausePageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  quote1: PropTypes.string,
  author1: PropTypes.string,
  title2: PropTypes.string,
  description2: PropTypes.string,
  section2_img: PropTypes.string,
  section2_alt: PropTypes.string,
  quote3: PropTypes.string,
  author3: PropTypes.string,
  title4: PropTypes.string,
  description4: PropTypes.string,
  section4_img: PropTypes.string,
  section4_alt: PropTypes.string,
  quote5: PropTypes.string,
  author5: PropTypes.string,
  title6: PropTypes.string,
  description6: PropTypes.string,
  section6_img: PropTypes.string,
  section6_alt: PropTypes.string,
  quote7: PropTypes.string,
  author7: PropTypes.string,
}

export default CausePageTemplate